const privateMethods = new Map();

class CrossPageInteraction
{
  constructor ()
  {
    // create a localstorage key
    const createLocalKey = () =>
    {
      return 'codigger-cross-page-interaction-key';
    };

    const getLocalData = () =>
    {
      const crossPageInteractionKey = createLocalKey();
      try
      {
        const data = localStorage.getItem(crossPageInteractionKey);
        if (data)
        {
          localStorage.removeItem(crossPageInteractionKey)
          const json = JSON.parse(data);
          // within prescription
          if (+new Date() - json.now < json.timeout * 1000)
          {
            return json.params || {};
          }
        }
        return {};
      }
      catch (e)
      {
        console.warn('jump data irregularity');
        console.warn(e);
        return {};
      }
    };

    /* set localstorage data
    * @param {object} options 
    */
    const setLocalData = (options = {}) =>
    {
      const crossPageInteractionKey = createLocalKey();
      localStorage.setItem(crossPageInteractionKey, JSON.stringify({
        now: +new Date(),
        timeout: options.timeout || 30,
        params: options
      }));
    };

    privateMethods.set('getLocalData', getLocalData);
    privateMethods.set('setLocalData', setLocalData);
  }

  get jumpData ()
  {
    const getLocalData = privateMethods.get('getLocalData');
    return getLocalData();
  }
  
  /**
   * A jumpto B
   * @param {object} options jumpParams 
   * @param {number} options.timeout unit second default:30
   * @param {number} options.delay wait for jump second defalut:0
   * @param {string} path page
   */
  jump (options = {}, path = '/web/desktop/')
  {
    if (Object.prototype.toString.call(options) !== '[object Object]')
    {
      options = {};
    }
    const setLocalData = privateMethods.get('setLocalData');
    setLocalData(options);
    const delay = typeof options.delay === 'number' ? options.delay : 0;
    if (options.delay !== -1)
    {
      setTimeout(() =>
      {
        window.open(path, options._self ? '_self' : '_blank');
      }, delay * 1000);
    }
  }
}

const crossPageInteraction = new CrossPageInteraction();

export default crossPageInteraction;
