/*
 * @Author: laibin
 * @email: laisan8848@163.com
 * @Date: 2025-02-26 11:42:28
 * @LastEditors: laibin
 * @LastEditTime: 2025-03-03 09:11:22
 * @Description: file content
 */


import './assets/css/reset.css';
import taskChain from './utils/taskChain';
import {initIdeSocket} from './network/index';
import { initRouter } from './router';
import { initUser } from './user';
import {baseURL} from './api/http'
// if(window.top.electronAPI)
// {
//   window.top.electronAPI?.send('web-delete-indexDBLock');
// }

try
{
  const isIframe = window.top !== window.self;
  const url = window.location.href;
  window.kioskLogin = false;
  if(window.electronAPI)
  {
    if(window.electronAPI?.kioskType && window.electronAPI?.kioskType() == 0)
    {
      window.kioskLogin = true;
    }
  }
  if(!window?.top?.codigger)
  {
    // The order is important
    taskChain.push([initUser]);
    if(!window.kioskLogin && !isIframe && window.initIframe !== false)
    {
      taskChain.push(initRouter);
    }
    // taskChain.push(initIdeSocket);
    taskChain.startExecute();
  }
  else if(url.endsWith('.html'))
  {
    taskChain.push(initRouter);
    // taskChain.push(initIdeSocket);
    taskChain.startExecute();
  }
}
catch (error)
{
  console.log('core error', error);
}

window.top.codigger.getComponent = (Vue) =>
{
  window.Vue = Vue;
  setTimeout(async () =>
  {
    const errMessage = await window.System?.import();
    console.log(errMessage, 'errMessage');
    window.top.codigger.errorMessage = errMessage.default;
  })
}

window.top.codigger.getErrorUrl = (Vue, moduleName, version='') =>
{
  window.Vue = Vue
  const name = moduleName.split('-');
  return `${baseURL.remote}/${name[1]}/${moduleName}/js/${moduleName}${version}.js?v=${new Date().getTime()}`
}