import { createUuid } from '../utils/index';

export const WEBSOCKET_CODE = {
  'OK': 0,
  'PING': 61001,
  'PONG': 61002,
  'VIMERROR': 3,
  'VIEWCACHE': 20304,
  'STARTUP': 20305,
  'CONTENT_FIELDS_INVALID': 60021,
  'CONTENT_FIELDS_LACK': 60022,
  'CONTENT_FIELDS_MISMATCH': 60023,
  'LOGOFF': 60024,
  'CONTENT_CONTINUE': 60025,
  'STARTUP_EVENT': 60026,
  'SPLIT': 5,
  'SPLITEND': 50,
  'FINDVIMPID': 60000,
  'OPENFAIL': 604501,
  'OPERATIONFAIL': 604502,
  'CREATEFAIL': 604503,
  'NOMAVEN': 60404,
  'PREVIEW': 62033,
  'LOGGING': 62039,
  'START_FEEDBACK': 60300, // 启动反馈
  'NOSUCH': 60431, // no such mapping
  'FILEFAIL': 604506,
  'DIRFAIL':604505,
  'NOPROJECT':604507,
  'AGENTERROR': 404,
  'NOTICE':62038,
  'NEED_INSTALL_MODULE':604489,
  'PUT_PEERS':62024,
  'OSE_CLIENT':62042
};


export const createMessage = ({
  projectId,
  cusAgentId,
  broswerId,
  peerId,
  connectionId,
  command,
  code = WEBSOCKET_CODE.OK,
  cooperId = ''
}) =>
{
  let cmd,
    src,
    type,
    dstPeer = '1',
    dstProjectId = projectId,
    mid = Number(createUuid());
  let mudemId = cusAgentId;
  if (typeof command === 'object')
  {
    cmd = command.cmd;
    type = command.type;
    dstPeer = command.dstPeer || peerId;
    if(command.dstProjectId)
    {
      dstProjectId = command.dstProjectId;
    }
    // 特殊mid传值，例如find vim 60000
    if(command.mid)
    {
      mid = command.mid;
    }
    if(command.agentId)
    {
      mudemId = command.agentId;
    }
    // O0829兼容
    if(command.mudemId)
    {
      mudemId = command.mudemId;
    }
  }
  else if(typeof command === 'string')
  {
    if(command === '')
    {
      return;
    }
    // 预览永中服务的文件
    if(code === WEBSOCKET_CODE.PREVIEW)
    {
      cmd = command;
      dstProjectId = '1';
      dstPeer = '0';
      mid = WEBSOCKET_CODE.PREVIEW;
    }
    else
    {
      // 截取最后一段src作为dst.peerId
      let arr = JSON.parse(command.replaceAll('\'', '"'));

      src = arr.pop();
      if(src === '0:0:0')
      {
        src = '0:0:lifter';
      }
      if(!src)
      {
        return;
      }
      try
      {
        dstPeer = src.split(':')[2];
        cmd = JSON.stringify(arr);
      }
      catch (e)
      {
        if(e.message.includes('src.split is not a function'))
        {
          alert(`不是标准的四段式vimcall，请检查${command}`);
          return;
        }
      }
    }
  }

  if(!mudemId)
  {
    // console.error('agentId为空');
    console.error('mudemId为空');
    return;
  }
  let jsonData = {};
  jsonData.th = {
    'version': 2,
    'qos': 0,
    'ttl': 0,
    // 前端src的agentId和peerId是固定的
    'src': {
      'projectId': projectId,
      'mudemId': broswerId || '1',
      'peerId': '1'
    },
    // 目的地址的peerId是需要根据发往agent和vim来区分的，发往agent的话dst.peerId = 1;发往vim需要根据从vim返回的数据里取得
    // 目的地址的projectId是需要根据发往agent和vim来区分的，发往agent的话dst.projectId = 1;发往vim则使用当前projectId
    'dst': {
      'projectId': dstProjectId,
      'mudemId': mudemId,
      'peerId': dstPeer
    }
  };
  jsonData.eh = {
    'connectionId': connectionId,
    'packetId': createUuid(),
    'timestamp': new Date().getTime()
  };
  if(cooperId)
  {
    jsonData.eh = Object.assign({}, jsonData.eh, {'cooperId':cooperId});
  }
  // mid === 60000时为查找vim进程
  jsonData.ah = {
    'code': code,
    'mid': mid,
    'data': cmd
  };
  if(type)
  {
    jsonData.ah.type = type;
  }
  return {'message':jsonData, mid};

};

export const generateMessage = ({dst, srcProjectId, srcMudemId, srcPeerId, dstProjectId, dstMudemId, dstPeerId, code, mid, command, connectionId}) =>
{
  const th = {
    'version': 2,
    'qos': 0,
    'ttl': 0,
    // 前端src的agentId和peerId是固定的
    'src': {
      'projectId': srcProjectId,
      'mudemId': srcMudemId || '1',
      'peerId': srcPeerId || '1'
    },
    // 目的地址的peerId是需要根据发往agent和vim来区分的，发往agent的话dst.peerId = 1;发往vim需要根据从vim返回的数据里取得
    // 目的地址的projectId是需要根据发往agent和vim来区分的，发往agent的话dst.projectId = 1;发往vim则使用当前projectId
    'dst': {
      'projectId': dstProjectId,
      'mudemId': dstMudemId,
      'peerId': dstPeerId
    }
  };
  if(dst)
  {
    Object.assign(th, {dst});
  }
  const jsonData = {
    th,
    'eh':{
      'connectionId': connectionId,
      'packetId': createUuid(),
      'timestamp': new Date().getTime()
    },
    'ah':{
      'code': code,
      'mid': mid || Number(createUuid()),
      'data': command
    }
  };
  return jsonData;
};