import Dexie from 'dexie';

export default class kvStorage
{
  constructor (dbName = 'CodiggerKeyValueDB')
  {
    this.db = new Dexie(dbName);
    // 定义数据库结构
    this.db.version(1).stores({
      'CodiggerKeyValue': '++id, username, modulename, key, value'
    });

    // 确保数据库打开
    this.db.open().catch((err) =>
    {
      console.error('Failed to open database:', err);
    });
  }

  // 设置键值对
  async setItem (username, modulename, key, value)
  {
    await this.db.CodiggerKeyValue.put({ username, modulename, key, value });
  }

  // 获取键对应的值
  async getItem (username, modulename, key)
  {
    const result = await this.db.CodiggerKeyValue.get({ username, modulename, key });
    return result ? result.value : null;
  }

  // 删除键
  async removeItem (username, modulename, key)
  {
    await this.db.CodiggerKeyValue.delete([username, modulename, key]);
  }

  // 清空存储
  async clear (username, modulename)
  {
    await this.db.CodiggerKeyValue.where({ username, modulename }).delete();
  }

  // 检查某个键是否存在
  async hasKey (username, modulename, key)
  {
    const result = await this.db.CodiggerKeyValue.get({ username, modulename, key });
    return result !== undefined;
  }

  // 获取所有键
  async getKeys (username, modulename)
  {
    const keys = [];
    await this.db.CodiggerKeyValue.where({ username, modulename }).each((item) =>
    {
      keys.push(item.key);
    });
    return keys;
  }

  // 获取所有存储的数据
  async getAllItems (username, modulename)
  {
    const items = {};
    await this.db.CodiggerKeyValue.where({ username, modulename }).each((item) =>
    {
      items[item.key] = item.value;
    });
    return items;
  }
}

// // 使用示例
// const storage = new DexieStorage();

// // 设置数据
// storage.setItem('user1', 'module1', 'key1', { 'name': 'John Doe', 'age': 30 }).then(() =>
// {
//   console.log('Data set successfully');
// });

// // 获取数据
// storage.getItem('user1', 'module1', 'key1').then((user) =>
// {
//   console.log('User:', user); // { name: 'John Doe', age: 30 }
// });

// // 检查键是否存在
// storage.hasKey('user1', 'module1', 'key1').then((exists) =>
// {
//   console.log('Key exists:', exists); // true
// });

// // 获取所有键
// storage.getKeys('user1', 'module1').then((keys) =>
// {
//   console.log('Keys:', keys); // ['key1']
// });

// // 获取所有存储的数据
// storage.getAllItems('user1', 'module1').then((items) =>
// {
//   console.log('All items:', items); // { key1: { name: 'John Doe', age: 30 } }
// });

// // 删除数据
// storage.removeItem('user1', 'module1', 'key1').then(() =>
// {
//   console.log('Key removed');
// });

// // 清空存储
// storage.clear('user1', 'module1').then(() =>
// {
//   console.log('Storage cleared');
// });