import {
  queryAppAnchorList,
  queryFileExtensionHandler,
  createRoseFileExtensionHandler,
  addDesktopFile,
  queryDesktopFileList,
  queryDesktopFile,
  queryFileAnchor,
  queryFileAnchorList,
  addFileAnchor,
  updateFileExtensionSeq
} from '@/api/fileAnchor';

async function addFileExtensionHandler (data)
{
  await createRoseFileExtensionHandler(data);
}

// 新增file anchor的文件
async function addFile (fileInfo)
{
  const { absolutePath, type, deviceId, workstationId } = fileInfo;
  const params = { absolutePath, type, deviceId, workstationId };
  await addDesktopFile(params);
  const resFile = await queryDesktopFile({
    absolutePath,
    workstationId
  });
  return resFile;
}

/**
 * 通过file anchor信息打开文件
 * @param {*} fileInfo 文件信息
 * @param {*} openTypeInfo 文件打开方式信息，包含appAnchor和fileExtensionHandler信息
 * @returns
 */
async function openFileByFileAnchor (fileInfo, openTypeInfo)
{
  // 1.查询desktop file信息，如果当前file已被file anchor打开过，生成过desktop file信息，下一步
  const { absolutePath, workstationId, fileExtension } = fileInfo;
  const { moduleName, parentEnv } = openTypeInfo;
  const params = {
    absolutePath,
    workstationId
  };
  const resFile = await queryDesktopFile(params);
  let file = resFile;
  // 2.如果当前file没有被file anchor打开过，无desktop file信息，则新增，下一步
  if (file === null)
  {
    file = await addFile(fileInfo);
  }

  // 3.如果当前file有file anchor信息，使用已有的file anchor信息
  const resFileAnchor = await queryFileAnchor(params);
  let fileAnhcor = resFileAnchor;
  // 4.如果当前file无file anchor信息，则新增file anchor
  if (fileAnhcor === null)
  {
    const fileAnchorParams = {
      'fileId': file.id,
      'fileExtensionHandlerId': openTypeInfo.id
    };
    await addFileAnchor(fileAnchorParams);
  }

  const data = {
    'appModuleName': moduleName,
    'envName': parentEnv,
    'path': `#/?workstationId=${file.workstationId}&deviceId=${file.deviceId}&absolutePath=${file.absolutePath}&fileExtension=${fileExtension}`
  };

  return data;
}

/**
 * 文件的右键打开方式列表
 * @param fileExtension
 * @returns openTypeInfos
 */
async function queryFileOpenTypes (fileExtension)
{
  try
  {
    // 1.根据后缀名查询fileExtensionHandler列表信息，用来查询appAnchor列表，用来展示在右键打开方式列表中
    const fileExtensionHandlerList = await queryFileExtensionHandler({ fileExtension });
    if (!Array.isArray(fileExtensionHandlerList) || fileExtensionHandlerList.length === 0)
    {
      return [];
    }

    const ids = fileExtensionHandlerList.map((e) => e.appAnchorId).join(',');
    // 2.查询appAnchor列表，用来展示在右键打开方式列表中
    const appAnchorList = await queryAppAnchorList({ ids, sort:'ids' });
    if (!Array.isArray(appAnchorList) || appAnchorList.length === 0)
    {
      return [];
    }
    const openTypeInfos = appAnchorList.map((appAnchor) =>
    {
      const fileExtensionHandler = fileExtensionHandlerList.find((handler) => handler.appAnchorId === appAnchor.id);
      return {...appAnchor, ...fileExtensionHandler};
    });
    return openTypeInfos;
  }
  catch (error)
  {
    console.error(error);
  }
  return [];
}
async function setFileExtensionSeq (data)
{
  await updateFileExtensionSeq(data);
}
const fileAnchor = {
  addFileExtensionHandler,
  addFile,
  queryFileOpenTypes,
  openFileByFileAnchor,
  setFileExtensionSeq
};
export default fileAnchor;
