class LocalInstance
{
  constructor(options)
  {
    this.connectionId = options.connectionId;
    this.ackReport = options.ackReport;
  }

  async getLocalInstanceKey()
  {
    const currentCollection = await top.DesktopPool?.getCurrentCollection?.() || {};
    return `codigger-desktopInstance-${top?.codigger?.user?.info?.username}-${currentCollection?.id || 0}`;
  }

  // 获取本地数据
  async getLocalCache()
  {
    const key = await this.getLocalInstanceKey();
    let json = {
      connectionId: this.connectionId,
      list: []
    };
    try
    {
      const localData = localStorage.getItem(key);
      if (localData)
      {
        json = JSON.parse(localData);
      }
    }
    catch (e)
    {
      console.error(e);
    }
    return json;
  }
  
  // 添加本地数据
  async addLocalCache(data)
  {
    const key = await this.getLocalInstanceKey();
    const json = await this.getLocalCache();
    const list = json.list;
    const idx = list.findIndex((v) => v.instanceId === data.instanceId);
    if (idx > -1)
    {
      list.splice(idx, 1);
    }
    list.push(data);
    json.list = list;
    localStorage.setItem(key, JSON.stringify(json));
  }
  
  // 移除本地数据
  async removeLocalCache(data)
  {
    const key = await this.getLocalInstanceKey();
    const json = await this.getLocalCache();
    const list = json.list;
    const idx = list.findIndex((v) => v.instanceId === data.instanceId);
    if (idx > -1)
    {
      list.splice(idx, 1);
    }
    json.list = list;
    localStorage.setItem(key, JSON.stringify(json));
  };
  
  // 清除数据,清除前上报
  async clearLocalCache()
  {
    const key = await this.getLocalInstanceKey();
    const json = await this.getLocalCache();
    const list = json.list;
    list.forEach((v) =>
    {
      this.ackReport({
        ...v,
        state: 'stopped'
      });
    });
    localStorage.removeItem(key);
  };
}

export default LocalInstance;
