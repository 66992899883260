import httpClient, { baseURL } from '@/api/http';

function getBase64File (data)
{
  return httpClient.post({
    url: `${baseURL.edge}/mudem/download_file`,
    data: {
      file_name: data.fileName,
      file_path: data.filePath,
      workstation_id: data.workstationId
    },
    oauth: true,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    successCodes: 0
  });
};

export async function getWorkstationResource (data = {})
{
  if (!data.fileName || !data.filePath || !data.workstationId)
  {
    return Promise.reject('fileName,filePath,workstationId缺一不可');
  }
  try
  {
    const base64 = await getBase64File(data);
    const bstr = atob(base64);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--)
    {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file = new File([u8arr], data.fileName, { type: 'image/png' });
    return Promise.resolve(file);
  }
  catch (e)
  {
    return Promise.reject(e);
  }
};
