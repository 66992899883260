import { createUuid } from '@/utils';
import myAppInfo from './appInfo';
import { getElement, getDesktopInstance } from "./utils";
import { TBCLS } from "./constants";

const privateMethods = new Map();

class Register
{
  constructor ()
  {
    /**
     * inject style in window
     * @param {object} instance - window
     */
    const injectStyle = (instance) =>
    {
      const controlKey = `control-${createUuid()}`;
      const titlebarKey = `titlebar-${createUuid()}`;
      const bodyKey = `body-${createUuid()}`;
      const style = instance.document.createElement('style');
      style.innerHTML = `
        *[${titlebarKey}="show"]{
          transition: opacity .2s, visibility .2s, height .3s;
        }
        *[${titlebarKey}="hide"]{
          height: 0 !important;
          opacity: 0 !important;
          visibility: hidden !important;
          margin-top: 0 !important;
          margin-bottom: 0 !important;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          transition: opacity .2s, visibility .2s, height .3s;
        }
        *[${bodyKey}="spread"]{
          z-index: 100 !important;
          height: 100% !important;
          top: 0 !important;
          transition: opacity .2s, visibility .2s, height .3s;
        }
        *[${bodyKey}="shrink"]{
          transition: opacity .2s, visibility .2s, height .3s;
        }
      `;
      instance.document.head.appendChild(style);
      const desktopInstance = getDesktopInstance(instance);
      if (desktopInstance)
      {
        const desktopStyle = desktopInstance.document.createElement('style');
        desktopStyle.innerHTML = `
          *[${controlKey}="hide"] {
            opacity: 0 !important;
            visibility: hidden !important;
            transition: none !important;
            display: none !important;
          }
          *[${controlKey}="show"] {
          }
        `;
        desktopInstance.document.head.appendChild(desktopStyle);
      }
      return {
        titlebarKey,
        bodyKey,
        controlKey
      };
    };

    privateMethods.set('injectStyle', injectStyle);
  }

  /**
   * register titlebar
   * @param {number} anchorId
   * @param {string|HTMLElement} node
   */
  registerTitlebar (anchorId, node)
  {
    const appInfo = myAppInfo.get({ anchorId })[0];
    if (appInfo)
    {
      const titlebarElement = getElement(node, appInfo.instance?.document);
      if (titlebarElement)
      {
        myAppInfo.set({
          ...appInfo,
          titlebarElement
        });
      }
    }
  }

  /**
   * cancel register titlebar
   * @param {number} anchorId
   */
  unRegisterTitlebar (anchorId)
  {
    const appInfo = myAppInfo.get({ anchorId })[0];
    if (appInfo)
    {
      myAppInfo.set({
        ...appInfo,
        titlebarElement: null
      });
    }
  }

  /**
   * register windowBody
   * @param {number} anchorId
   * @param {string|HTMLElement} node
   */
  registerBody (anchorId, node)
  {
    const appInfo = myAppInfo.get({ anchorId })[0];
    if (appInfo)
    {
      const element = getElement(node, appInfo.instance?.document);
      if (element)
      {
        myAppInfo.set({
          ...appInfo,
          bodyElement: element
        });
      }
    }
  }

  /**
   * cancel register windowBody
   * @param {number} anchorId
   */
  unRegisterBody (anchorId)
  {
    const appInfo = myAppInfo.get({ anchorId })[0];
    if (appInfo)
    {
      myAppInfo.set({
        ...appInfo,
        bodyElement: null
      });
    }
  }

  /**
   * register app
   * @param {object} appInfo 
   * @param {object} instance - windows
   */
  register (appInfo, instance)
  {
    if (instance)
    {
      const desktopInstance = getDesktopInstance(instance);
      let controlElement = null;
      if (desktopInstance)
      {
        controlElement = desktopInstance.document.querySelector(`#window-${appInfo.windowId} .window-control`);
      }
      let titlebarElement = null;
      for (let i = 0; i < TBCLS.length; i++)
      {
        const element = instance.document.querySelector(TBCLS[i]);
        if (element)
        {
          titlebarElement = element;
          break;
        }
      }
      const keys = privateMethods.get('injectStyle')(instance);
      myAppInfo.set({
        ...appInfo,
        titlebarElement,
        controlElement,
        ...keys,
        instance
      });
    }
  }

  /**
   * cancel register app
   * @param {object} appInfo 
   */
  unRegister (appInfo)
  {
    myAppInfo.remove(appInfo);
  }
}

export default new Register();
