import { Icon, templateIcon } from './icon';
import { createNotificationMessage } from './notification';
import codiggerBroadcast from '../utils/broadcast/index';
import { createUuid } from '../utils';
import sendUserNotice from '../utils/sendUserNotice';
import * as i18n from '../i18n';
class Notice {
  constructor () {
    this.defaultMessage = {
      icon: 'success',
      message: 'Success',
      messageEn: '',
      id: null,
      duration: 3000,
      height: 44,
      type: 'info',
      scrollTimes: 2, 
      callbackBtn: '',
      sendNotice: true,
      moduleName: 'app-onecloud-system-keeping',
      instanceId: 1,
      button: null,
      callback: () =>
      {
        // 1.用来调用app自身的函数，来执行app内部通知的逻辑，例如meeting的join通知
        // 2.用来调用notice SDK的notifyNotice函数，实现app发消息通知desktop的notice app的能力
      }
    };
    this.broadcast = new codiggerBroadcast();
    this.messageList = [];
  }

  removeMessage (target)
  {
    const idx = this.messageList.findIndex((v) => v[0].isEqualNode(target));
    if (idx > -1) {
      this.messageList[idx][1].remove();
      this.messageList.splice(idx, 1);
      const index = this.messageList.findIndex((v) => v[0].isEqualNode(target));
      if(index > -1)
      {
        this.messageList[index][1].start(true);
      }
    }
  }

  stopMessageTime (target)
  {
    const arr = this.messageList.filter((v) => v[0].isEqualNode(target));
    arr.forEach((item, index) =>
    {
      if(index !== 0)
      {
        item[1].stop(true);
        item[1].setZindex(-1);
        item[1].resetScrollTimes();
      }
    }) 
  }

  createNoticeDom (config, messageKey)
  {
    let countdown = Math.ceil(config.duration / 1000);
    if(countdown > 99) countdown = 99;
    let btnText = (config.sendNotice && config.id || config.callbackBtn) ? i18n.t('toast.text') : '';
    const htmlString = `
      <style  type="text/css">
      .titlebar-notice {
        color: #FFFFFF; 
        width: 1024px; 
        height: ${config.height}px; 
        position: absolute; 
        top: 0; left: 0; 
        z-index: 999; 
        display: flex; 
        align-items: center;
        padding-left: 12px;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #1A5A87 30%);
        user-select: none;
      }
      .titlebar-notice-container {
        display: flex;
        height: 100%; 
        align-items: center;
        animation: slideInFromLeft 0.5s ease-out forwards;
      }
      @keyframes slideInFromLeft {
        from {
          transform: translateX(-50px);
          opactity: 0;
        }
        to {
          transform: translateX(0);
          opactity: 1;
        }
      }
      .titlebar-notice.info {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #1A5A87 30%);
      }
      .titlebar-notice.error {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #B21657 30%);
      }
      .titlebar-notice.alert {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #C26421 30%);
      }
      .titlebar-notice.success {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #2B7235 30%);
      }
      .titlebar-notice .icon {
        width: 24px;
        height: 24px;
      }
      .titlebar-notice .scroll-text-container {
        width: 648px;
        height: 100%;
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-left: 12px;
        margin-right: 16px;
        font-size: 16px;
        color: #FFFFFF;
        position: relative;
      }
      .scroll-mode::after{
        position: absolute;
        right: 0;
        top: 0;
        content: '';
        width: 64px;
        height: 100%;
      }
      .scroll-text {
        display: inline-block;
      }
      .paused {
        animation-play-state: paused;
      }
      .titlebar-notice .btn {
        width: 76px;
        height: 28px;
        background: rgba(0, 0, 0, 0.24);
        box-sizing: border-box;
        border: 1px solid #FFFFFF;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.9);
        margin-right: 20px;
        text-align: center;
        padding: 0 15px;
        cursor: pointer;
        justify-content: center;
      }
      .titlebar-notice .btn-box {
        display: none;
        width: 76px;
        height: 28px;
        margin-left: 8px;
        margin-right: 16px;
      }
      .titlebar-notice .btn .btnText {
        min-width: 50px;
        display: inline-block;
        text-align: center;
        overflow: hidden;
      }
      .titlebar-notice .btn img{
        width: 16px;
        height: 16px;
      }
      .titlebar-notice .notice-close {
        width: 26px;
        height: 22px;
        box-sizing: border-box;
        padding-left: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: url(${templateIcon.closeBox});
        background-size: 26px 22px;
        position: relative;
      }
      .titlebar-notice .notice-close .countdown{
        width: 22px;
        height: 22px;
        line-height: 22px;
        text-align: center;
      }
      .titlebar-notice .notice-close .close{
        display: block;
        width: 26px;
        height: 22px;
        background: url(${templateIcon.closeIcon});
        background-size: 26px 22px;
        position: absolute;
        top: 0;
        left: 0;
      }
      </style>
      <div class="titlebar-notice-container">
        <img class="icon" src="${Icon[config.icon] || Icon['complete']}" />
        <div class="scroll-text-container scroll-container-${messageKey}">
          <div class="scroll-text scroll-text-${messageKey}" id="scrollText-${messageKey}">${config.message}</div>
        </div>
        <div class="btn-box btn-box-${messageKey}">
          <div class="btn btn-${messageKey}">
            <span class="btnText">${btnText}</span>
          </div>
        </div>
        <div class="notice-close notice-close-${messageKey}">
          <div class="countdown countdown-${messageKey}" style="display: none;">${countdown}</div>
          <div class="close close-${messageKey}"></div>
        </div>
      </div>
    `;
    return htmlString;
  }

  getAppFull(config) {
    let appFull = false;
    if(window?.top?.DesktopPool?.Application)
    {   
      const application = new window.top.DesktopPool.Application(config.iframeId);
      appFull = application.getFullScreen();
    }
    return appFull;
  }

  createTitleNotice (config) {
    const target = config.target;
    const messageKey = createUuid();
    const messageId = `${config.moduleName}-${messageKey}`; 
    config.id = messageId;
    const noticeDiv = document.createElement('div');
    noticeDiv.classList.add(`titlebar-notice`);
    noticeDiv.classList.add(`titlebar-notice-${messageKey}`);
    noticeDiv.classList.add(config.type);
    noticeDiv.innerHTML = this.createNoticeDom(config, messageKey);

    let interval = null;
    let timer = null;
    let totalTime = 0;
    let status = 0;
    let waitTime = 3;
    let pause = false;
    
    let currentScrollTimes = 0;
    let scrollMode = false;

    const operateIn = (config.moduleName === 'app-onecloud-system-keeping' || config.moduleName === 'app-essential-store') ? 'Kernel Space' : 'User Space';
    const messageInfo = {
      username: window.top.codigger.user.info.username,
      titleZh: config?.i18nName?.zh || 'Toast',
      titleEn: config?.i18nName?.en || 'Toast',
      moduleName: config.moduleName,
      peerName: 'browser',
      instanceId: config.instanceId,
      contentZh: config.message,
      contentEn: config.messageEn ? config.messageEn : config.message,
      messageId,
      type: 2,
      buttonNameZh: config?.button?.zh || null,
      buttonNameEn: config?.button?.en || null,
      buttonParams: config?.button?.params || null,
      buttonType: 1,
      operateIn: operateIn
    };
    
    sendUserNotice(messageInfo);

    target.appendChild(noticeDiv);

    if(config.sendNotice && config.id || config.callbackBtn)
    {
      target.querySelector(`.btn-box-${messageKey}`).style.display = 'block';
      target.querySelector(`.scroll-container-${messageKey}`).style.width = '548px';
    }

    // scroll start
    const scrollTextElement = target.querySelector(`#scrollText-${messageKey}`);
    const scrollSpeed = 60;
    // // 滚动完整文本加容器宽度所需时间
    const containerElement = scrollTextElement.parentElement;
    const containerWidth = containerElement.offsetWidth;

    const textWidth = scrollTextElement.offsetWidth;
    const scrollWidth = textWidth - containerWidth;
    const animationDuration = Math.ceil((scrollWidth) / scrollSpeed);  // 计算滚动一次所需的动画时间（秒）

    const messageDuration = Math.ceil(config.duration / 1000) > 99 ? 99 : Math.ceil(config.duration / 1000);

    const sw = messageDuration * 60;
    let aniTime = animationDuration;
    let nw = scrollWidth;
    if(textWidth > containerWidth)
    {
      let sc = Math.ceil(sw / textWidth);
      let separator = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
      let massagestr = config.message;
      if(sc < config.scrollTimes)
      {
        sc = config.scrollTimes;
      }
      if(sc > 1) {
        let scount = sc + 1;
        if(scount < config.scrollTimes)
        {
          scount = config.scrollTimes;
        }
        massagestr = Array(scount).fill(config.message).slice(0, -1).join(separator);
      }
      scrollTextElement.innerHTML = massagestr;
      nw = scrollTextElement.offsetWidth - containerWidth;
      aniTime =  Math.ceil(nw / scrollSpeed);
    }

    let scrollTimes = Math.ceil(messageDuration / (animationDuration));
    const addKeyframesRule = () =>
    {
      const styleElement = document.createElement('style');
      styleElement.type = 'text/css';
      const keyframesRule = `
      .scroll-text-${messageKey} {
        animation: scrollText-${messageKey} ${aniTime}s linear infinite;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-play-state: running;
      }
      @keyframes scrollText-${messageKey} {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-${nw}px);
        }
      }
      /* hover 暂停 */
      .titlebar-notice-${messageKey}:hover .scroll-text-container .scroll-text-${messageKey} {
        animation-play-state: paused;
      }
      `;
      let targetElement = target.querySelector('.titlebar-notice-container');
      styleElement.appendChild(document.createTextNode(keyframesRule));
      targetElement?.parentNode?.insertBefore(styleElement, targetElement);
    };

    if(textWidth > containerWidth)
    {
      scrollMode = true;
      target.querySelector(`.notice-close-${messageKey}`).style.display = 'none';
      target.querySelector(`.btn-${messageKey}`).style.display = 'none';
      containerElement.classList.add('scroll-mode');
      setTimeout(() =>
      {
        addKeyframesRule();
        addListenAnimationend();
      }, 3000);
    }

    let _that = this;
    let isEnd = false;
    const listenAnimationend = () =>
    {
      let scrollInterval = setInterval(() => {
        if(pause) return;
        if(scrollInterval) clearInterval(scrollInterval);
        scrollInterval = null;
        // containerElement.classList.add('scroll-mode');
        if(scrollTimes !== currentScrollTimes)
        {
          pause = false;
        }
        // resetScroll();
        _that.removeMessage(target);
      }, 3000);
    }
    const addListenAnimationend = () => {
      scrollTextElement.addEventListener('animationend', listenAnimationend);
    };  

    const removeListenAnimationend = () => {
      scrollTextElement.removeEventListener('animationend', listenAnimationend);
    };

    const removeScrollAnimation = () => {
      scrollTextElement.classList.remove(`scroll-text-${messageKey}`);
    };

    const addScrollAnimation = () => 
    {
      if (pause) return;
      scrollTextElement.classList.add(`scroll-text-${messageKey}`);
    };

    const resetScrollTimes = () =>
    {
      currentScrollTimes = 0;
      removeScrollAnimation();
    };

    const resetScroll = () =>
    {
      if (pause) return;
      removeScrollAnimation();
      setTimeout(() => 
      {
        addScrollAnimation();
      }, 10);
    };

    // scroll 
    target.querySelector(`.titlebar-notice-${messageKey}`).addEventListener('mouseover', function (e) {
      if(scrollMode)
      {
        target.querySelector(`.notice-close-${messageKey}`).style.display = 'block';
      }
      else 
      {
        target.querySelector(`.countdown-${messageKey}`).style.display = 'none';
        target.querySelector(`.close-${messageKey}`).style.display = 'block';
      }
      if((config.sendNotice && config.id) || config.callbackBtn)
      {
        target.querySelector(`.btn-box-${messageKey}`).style.display = 'block';
        target.querySelector(`.btn-${messageKey}`).style.display = 'flex';
      }
      stop();
    });
    target.querySelector(`.titlebar-notice-${messageKey}`).addEventListener('mouseout', function (e) {
      if(scrollMode)
      {
        target.querySelector(`.notice-close-${messageKey}`).style.display = 'none';
        target.querySelector(`.btn-box-${messageKey}`).style.display = 'none';
        target.querySelector(`.btn-${messageKey}`).style.display = 'none';
      }
      pause = false;
      if(scrollTimes === currentScrollTimes && isEnd && !pause)
      {
        setTimeout(() => {
          if(pause) return;
          _that.removeMessage(target);
        }, 3000);
      }
      start();
    });
  
    target.querySelector(`.notice-close-${messageKey}`).addEventListener('mouseover', function (e) {
      target.querySelector(`.countdown-${messageKey}`).style.display = 'none';
      target.querySelector(`.close-${messageKey}`).style.display = 'block';
    });

    target.querySelector(`.notice-close-${messageKey}`).addEventListener('mouseout', function (e) {
      target.querySelector(`.close-${messageKey}`).style.display = 'none';
      target.querySelector(`.countdown-${messageKey}`).style.display = 'block';
    });

    target.querySelector(`.notice-close-${messageKey}`).addEventListener('click', () => {
      if(timer) clearInterval(timer);
      if(interval) clearInterval(interval);
      this.removeMessage(target);
    });

    target.querySelector(`.btn-${messageKey}`).addEventListener('click', () => {
      if(config.sendNotice && config.id)
      {
        const appFull = _that.getAppFull(config);
        if(appFull)
        {
          createNotificationMessage(config, messageInfo, _that.broadcast);
        }
        else
        {
          this.notifyNotice(config.id);
        }
      }
      else
      {
        config.callback();
      }
      this.removeMessage(target);
    });

    const handleCountDown = () =>
    {
      if(timer) clearInterval(timer);
      if(target.querySelector(`.close-${messageKey}`)) {
        target.querySelector(`.close-${messageKey}`).style.display = 'block';
      }
      if(target.querySelector(`.countdown-${messageKey}`)) {
        target.querySelector(`.countdown-${messageKey}`).style.display = 'none';
      }

      timer = setInterval(() =>
      {
        if(interval) clearInterval(interval);
        if(status === 1) return;
        if(scrollMode) return;
        waitTime --;
        if(waitTime > 0) return;
        clearInterval(timer);
       
        totalTime = Math.ceil(config.duration / 1000);
        if(totalTime > 99) totalTime = 99;
        if(target.querySelector(`.close-${messageKey}`)) {
          target.querySelector(`.close-${messageKey}`).style.display = 'none';
        }
        if(target.querySelector(`.countdown-${messageKey}`)) {
          target.querySelector(`.countdown-${messageKey}`).innerHTML = totalTime;
          target.querySelector(`.countdown-${messageKey}`).style.display = 'block';
        }
        interval = setInterval(() =>
        {
          if(status === 1) return;
          if(totalTime > 0)
          {
            totalTime --;
            if(target.querySelector(`.countdown-${messageKey}`))
            {
              target.querySelector(`.countdown-${messageKey}`).innerHTML = totalTime;
            }
            if(totalTime === 0) 
            {
              clearInterval(interval);
              clearInterval(timer);
              waitTime = 3;
              this.removeMessage(target);
            }
          }
        }, 1000);
      }, 1000);
    };

    handleCountDown();

    const remove = () =>
    {
      // if(interval) clearInterval(interval);
      // if(timer) clearInterval(timer);
      target.removeChild(noticeDiv);
    };

    const start = (delay = false) =>
    {
      status = 0;
      pause = false;
      waitTime = 3;
      totalTime = Math.ceil(config.duration / 1000);
      handleCountDown();
      setZindex(999);
      addListenAnimationend();
      if(delay) {
        let _timer = setTimeout(() =>
        {      
          resetScroll();
          _timer = null;
        }, 3000);
      }
    };

    const stop = (flag = false) =>
    {
      status = 1;
      pause = true;
      if(flag)
      {
        removeListenAnimationend();
      }
    };

    const setZindex = (zIndex) =>
    {
      target.querySelector(`.titlebar-notice-${messageKey}`).style.zIndex = zIndex;
    };

    this.messageList.unshift([target, {remove, start, stop, setZindex, resetScrollTimes}]);
    this.stopMessageTime(target);

    const watchAppFullScreen = () =>
    {
      const full = new window.top.DesktopPool.Application(config.iframeId);
      full.watchFullScreen((val) =>
      {
        if(val)
        {
          _that.removeMessage(target);
        }
      });
    };

    // watchAppFullScreen();

    return {
      close: () => this.removeMessage(target),
      stop,
      start
    }
  }

  message (config) {
    if(!(Object.prototype.toString.call(config.target).includes('[object HTML'))) {
      console.error('Please use a legal Dom');
      return;
    }
    if(typeof config.duration !== 'number')
    {
      console.error('duration type is a number');
      return;
    }
    if(typeof config.message !== 'string')
    {
      console.error('message type is a string and needs to specify content');
      return;
    }
    if(!config.message || !(typeof config.message === 'string' && config.message.trim()))
    {
      console.error('message needs to specify content');
      return;
    }
    const appFull = this.getAppFull(config);
    if(config.bodyTarget && appFull)
    {
      config.target = config.bodyTarget;
    }

    return this.createTitleNotice({...this.defaultMessage, ...config});
  }

  success (config) {
    return this.message({type: 'success', message: 'Success', duration: 3000, icon:'complete', ...config});
  }

  error (config) {
    return this.message({type: 'error', message: 'Failed', duration: 3000, icon:'attention', ...config});
  }

  alert (config) {
    return this.message({type: 'alert', duration: 10000, icon:'message',  ...config});
  }

  info (config) {
    return this.message({type: 'info', icon:'message', duration: 5000, ...config});
  }

  notifyNotice (messageId) {
    // 具体实现逻辑要和notice的接收消息逻辑做对应
    console.log(messageId);
    this.broadcast.emit('application_message', {
      type: 'notice',
      data: {
        messageId,
        from: 'titlebar',
        open: true
      }
    });
  }
}

export const notice = new Notice();