/*
 * @Author: laibin
 * @email: laisan8848@163.com
 * @Date: 2024-09-02 12:39:08
 * @LastEditors: laibin
 * @LastEditTime: 2025-02-21 10:13:18
 * @Description: file content
 */
import { searchModule } from '../api';
import { set } from 'lodash';
import { createUuid } from '@/utils';
const getDesktopPoolMap = new Map();

export const getDesktopPool = () =>
{
  if (top.DesktopPool)
  {
    return Promise.resolve(top.DesktopPool);
  }
  return new Promise((resolve) =>
  {
    const key = createUuid();
    getDesktopPoolMap.set(key, resolve);
  });
};

export const initDesktopPool = async () =>
{
  let { moduleVersion } = await searchModule({'moduleName': 'web-onecloud-desktop'});
  if(moduleVersion.indexOf('1.0.') !== -1)
  {
    moduleVersion = '1.1.0';
  }
  let url = `/web/desktop.sys/${moduleVersion}/desktop-pool.js?v=${new Date().getTime()}`;
  const fn = new Function(`return import("${url}")`);
  const module = await fn();
  await module.initDesktop?.();
  set(window, 'top.DesktopPool', module);
  if (getDesktopPoolMap.size)
  {
    Array.from(getDesktopPoolMap).forEach((v) =>
    {
      v[1]?.(top.DesktopPool);
      getDesktopPoolMap.delete(v[0]);
    })
  }
}