import { isElement } from './utils';
import myAppInfo from './appInfo';

const privateMethods = new Map();

class Handle
{
  constructor ()
  {
    this.onTitlebarMap = new Map();
    this.titlebarStatus = {};

    const handleWindowControl = (status, appInfo) =>
    {
      if (!appInfo?.controlElement)
      {
        appInfo = myAppInfo.get(appInfo);
      }
      else 
      {
        appInfo = [appInfo];
      }
      appInfo.forEach((app) =>
      {
        app?.controlElement?.setAttribute?.(app.controlKey, status ? 'show' : 'hide');
      });
    };

    const handleTitlebar =  (appInfo, status, ext = {}) =>
    {
      const appList = myAppInfo.get(appInfo);
      appList.forEach((app) =>
      {
        // if is not pure,controls always show
        handleWindowControl(!ext.pure || status, app);
        if (app.titlebarElement && app.titlebarKey)
        {
          app.titlebarElement.setAttribute(app.titlebarKey, status ? 'show' : 'hide');
        }
        if (app.bodyElement && app.bodyKey)
        {
          app.bodyElement.setAttribute(app.bodyKey, status ? 'shrink' : 'spread');
        }
        this.titlebarStatus[appInfo.anchorId] = status;
        const onTitlebarFn = this.onTitlebarMap.get(String(app.anchorId));
        onTitlebarFn?.(status);
      });
    };

    const handleAllTitlebar = (status, ext) =>
    {
      myAppInfo.appInfoList.forEach((appInfo) =>
      {
        handleTitlebar(appInfo, status, ext);
      });
    };

    privateMethods.set('handleWindowControl', handleWindowControl);
    privateMethods.set('handleTitlebar', handleTitlebar);
    privateMethods.set('handleAllTitlebar', handleAllTitlebar);
  }

  showTitlebar (appInfo)
  {
    privateMethods.get('handleTitlebar')(appInfo, true);
  }

  hideTitlebar (appInfo, ext = { pure: false })
  {
    privateMethods.get('handleTitlebar')(appInfo, false, ext);
  }

  showAllTitlebar ()
  {
    privateMethods.get('handleAllTitlebar')(true);
  }

  hideAllTitlebar (ext = { pure: false })
  {
    privateMethods.get('handleAllTitlebar')(false, ext);
  }

  onTitlebar (anchorId, callback, immediate)
  {
    if (immediate)
    {
      callback?.(this.titlebarStatus[anchorId]);
    }
    this.onTitlebarMap.set(String(anchorId), callback);
  }
  
  showControl (appInfo)
  {
    privateMethods.get('handleWindowControl')(true, appInfo);
  }

  hideControl (appInfo)
  {
    privateMethods.get('handleWindowControl')(false, appInfo);
  }
}

export default new Handle();
