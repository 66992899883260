import closeBox from './icon/close-box.png';
import closeIcon from './icon/close-icon.png';
import detailIcon from './icon/detail-icon.png';

import close from './notice/close.png';
import closeHover from './notice/close-hover.png';
import messageDetail from './notice/icon-details.png';
import messageDetailActive from './notice/icon-details-active.png';

import alarm from './icon/alarm.png';
import attention from './icon/attention.png';
import complete from './icon/complete.png';
import device from './icon/device.png';
import message from './icon/message.png';
import profile from './icon/profile.png';
import secure from './icon/secure.png';
import setting from './icon/setting.png';
import share from './icon/share.png';
import wallet from './icon/wallet.png';

export const templateIcon = {
  closeBox,
  closeIcon,
  detailIcon
};

export const noticeIcon = {
  close,
  closeHover,
  messageDetail,
  messageDetailActive
};

export const Icon = {
  alarm,
  attention,
  complete,
  device,
  message,
  profile,
  secure,
  setting,
  share,
  wallet
};