/*
 * @Author: laibin
 * @email: laisan8848@163.com
 * @Date: 2024-08-07 20:29:17
 * @LastEditors: laibin
 * @LastEditTime: 2025-02-22 22:38:09
 * @Description: file content
 */
import '../alert/alert.css';
import queryString from 'query-string';
import {push, changeDesktop, login, refresh} from '../router';
import fullScreen from '../utils/fullScreen';
import loading from '../utils/loading';
import {setScreenResolution} from '../utils/screen';
import codiggerBroadcast from '../utils/broadcast/index';
import sendUserNotice from '../utils/sendUserNotice';
import { getWorkstationResource } from '../utils/getWorkstationResource';
import { upload } from '../utils/upload';
import crossPageInteraction from '../utils/crossPageInteraction';
import codiggerInstance from '../codiggerInstance';
import { appWindowRegister, appWindowHandle } from '../appWindow';
import {set} from 'lodash';
import * as i18n from '../i18n';
import * as storage from '../storage';
import api from '../api';
import HttpClient from '../api/http/HttpClient';
import httpClient from '../api/http';
import {
  baseServer,
  commonServer,
  portalServer,
  bossServer,
  baseURL,
  wsURL,
  edgeServer,
  systemKeepingServer,
  dataCenterServer,
  userServer,
  walletServer,
  updateBaseUrl
} from '../api/http';
import {initIdeSocket} from '../network';
import {VimcallSocket} from '../network/vimcall';
import {DtermSocket} from '../network/dterm';
import { getDesktopPool, initDesktopPool } from '../desktopPool';
import { getStorage, initStorage } from '../storage/storage';
import { injectFont } from '../font';
import { notice } from '../notice';
import fileAnchor from '../fileAnchor';

export let user = {};
export let token = null;

export const logout = () =>
{
  localStorage.removeItem('oauth-token');
  sessionStorage.clear();
  push('/web/portal/?redirect='+location.pathname + location.search);
  // codiggerInstance.reset();
  location.reload();
};


export const analyzeToken = (myToken) =>
{
  if(!myToken)
  {
    return;
  }
  const base64Data = myToken.split('.');
  const userData = JSON.parse(decodeURIComponent(escape(window.atob(base64Data[1].replace(/-/g, '+').replace(/_/g, '/')))));
  user = userData;
  set(window, 'top.codigger.user.info', user);
  token = myToken;
  localStorage.setItem('oauth-token', myToken);
  return userData;
};

export const initUser = (myToken) =>
{
  try
  {
    set(window, 'top.codigger.router.push', push);
    set(window, 'top.codigger.router.changeDesktop', changeDesktop);
    set(window, 'top.codigger.router.refresh', refresh);
    set(window, 'top.codigger.router.login', login);
    set(window, 'top.codigger.getDesktopPool', getDesktopPool);
    set(window, 'top.codigger.getStorage', getStorage);
    set(window, 'top.codigger.utils.fullScreen', fullScreen);
    set(window, 'top.codigger.utils.loading', loading);
    set(window, 'top.codigger.utils.crossPageInteraction', crossPageInteraction);
    set(window, 'top.codigger.i18n', i18n);
    set(window, 'top.codigger.font.inject', injectFont);
    set(window, 'top.codigger.router.initIdeSocket', initIdeSocket);
    set(window, 'top.codigger.network.HttpClient', HttpClient);
    set(window, 'top.codigger.network.httpClient', httpClient);
    set(window, 'top.codigger.network.baseServer', baseServer);
    set(window, 'top.codigger.network.commonServer', commonServer);
    set(window, 'top.codigger.network.portalServer', portalServer);
    set(window, 'top.codigger.network.bossServer', bossServer);
    set(window, 'top.codigger.network.baseURL', baseURL);
  }
  catch (error)
  {
    console.error('initUser', error);
  }
  
  return new Promise((resolve, reject) =>
  {
    const queryParams = queryString.parse(location.search);
    const oauthToken = queryParams?.jwtToken || myToken || localStorage.getItem('oauth-token');
    if(!oauthToken)
    {
      resolve();
      return;
    }
    token = oauthToken;
    localStorage.setItem('oauth-token', oauthToken);
    updateBaseUrl();
    try
    {
      set(window, 'top.codigger.user.info', user);
      set(window, 'top.codigger.user.logout', logout);
      set(window, 'top.codigger.utils.setScreenResolution', setScreenResolution);
      set(window, 'top.codigger.utils.broadcast', codiggerBroadcast);
      set(window, 'top.codigger.utils.sendUserNotice', sendUserNotice);
      set(window, 'top.codigger.utils.getWorkstationResource', getWorkstationResource);
      set(window, 'top.codigger.utils.upload', upload);
      set(window, 'top.codigger.appWindowRegister', appWindowRegister);
      set(window, 'top.codigger.appWindowHandle', appWindowHandle);
      set(window, 'top.codigger.codiggerInstance', codiggerInstance);
      set(window, 'top.codigger.notice', notice);
      set(window, 'top.codigger.api', api);
      set(window, 'top.codigger.network.VimcallSocket', VimcallSocket);
      set(window, 'top.codigger.network.DtermSocket', DtermSocket);
      set(window, 'top.codigger.network.baseURL', baseURL);
      set(window, 'top.codigger.network.wsURL', wsURL);
      set(window, 'top.codigger.network.edgeServer', edgeServer);
      set(window, 'top.codigger.network.systemKeepingServer', systemKeepingServer);
      set(window, 'top.codigger.network.dataCenterServer', dataCenterServer);
      set(window, 'top.codigger.network.userServer', userServer);
      set(window, 'top.codigger.network.walletServer', walletServer);
      set(window, 'top.codigger.storage', storage);
      set(window, 'top.codigger.fileAnchor', fileAnchor);
      initStorage();
      // initDesktopPool();
    }
    catch (error)
    {
      console.error('initUser', error);
    }
    resolve(analyzeToken(oauthToken));
    loading.init();
    return;
  });
};