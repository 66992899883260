import { noticeIcon } from './icon';
import { createUuid } from '../utils';
import dayjs from 'dayjs';
import * as i18n from '../i18n';

const cssStr = `
  <style type="text/css">
  .notification-box {
    width: 418px;
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-214px);
    user-select: none;
    z-index: 999;
  }
  .notification-card {
    background: linear-gradient(110deg, rgba(55, 73, 147, 0.8) 0%, rgba(42, 45, 67, 0.8) 100%); 
    border-radius: 12px;
    width: 418px;
    box-sizing: border-box;
    overflow: hidden;
    backdrop-filter: blur(10px);
  }
  .notification-card .card-header {
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 9;
  }
  .notification-card .card-header::before {
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(315deg, rgba(1, 105, 236, 0) 0%, #0169EC 100%);
  }
  .notification-card .card-header::after {
    content: "";
    width: 362px;
    height: 1px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.41) 100%);
    position: absolute;
    left: 16px;
    bottom: -1px;
  }
  .notification-card .card-header .card-header-title { 
    display: flex;
    align-items: center;
    position: relative;
  }
  .notification-card .card-header .card-header-title .logo {
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 6px;
  }
  .notification-card .card-header .card-header-title .text {
    font-size: 16px;
    font-weight: bold;
    padding: 0 8px;
    white-space: wrap;
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 280px;
    white-space: nowrap;
    color: #2082FF;
  }
  .notification-card .card-header .card-extra {
    width: 80px;
    text-align: right;
  }
  .notification-card .card-header .card-extra .details-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('${noticeIcon.messageDetail}');
    background-size: 20px 20px;
    cursor: pointer;
  }
  .notification-card .card-header .card-extra .details-icon.active {
    background-image: url('${noticeIcon.messageDetailActive}');
  }
  .notification-card .card-header .card-extra .close{
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('${noticeIcon.close}');
    background-size: 20px 20px;
    position: relative;
    z-index: 9;
    cursor: pointer;
    margin-left: 16px;
  }
  .notification-card .card-header .card-extra .close:hover {
    background-image: url('${noticeIcon.closeHover}');
  }
  .notification-card .card-body {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 12px 4px 12px;
    line-height: 20px;
    color: #FFFFFF;
    font-size: 14px;
    word-break: break-all;
    position: relative;
    z-index: 9;
  }
  .notification-card .card-body .message{
    line-height: 22px;
  }
  .notification-card .card-body .details-message {
    width: 394px;
    height: 164px;
    box-sizing: border-box;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.3);
    display: none;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0  16px 20px;
    margin-top: 4px;
    margin-bottom: 8px;
  }
  .notification-card .card-body .details-message .item{
    display: flex;
  }
  .notification-card .card-body .details-message .item .name{
    width: 95px;
    color: rgba(255, 255, 255, 0.7);
    text-align: right;
    line-height: 20px;
    margin-right: 24px;
  }
  .notification-card .card-body .details-message .item .value{
    color: #FFFFFF;
  }
  .notification-card .card-footer {
    width: 100%;
    height: 52px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 12px;
    position: relative;
    z-index: 9;
  }
  .notification-card .card-footer .time {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
  }
  .notification-card .card-footer .btn-wrap {
    display: flex;
    align-items: center;
  }
  .notification-card .card-footer .btn {
    height: 28px;
    padding: 0 14px;
    background: linear-gradient(0deg, #FF8000 0%, #F84D00 100%);
    border: 1px solid rgba(255, 255, 255, 0.2758);
    border-radius: 16px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
  }
  </style>
`;

function createNotificationMessage (config, info, broadcast)
{
  const lang = i18n.getLanguage();
  const noticeKey = createUuid();
  const htmlStr = 
  `
    ${cssStr}
    <div class="notification-card">
      <div class="card-header">
        <div class="card-header-title">
          <img class="logo" src="${config.appLogo}" alt="">
          <span class="text">${lang === 'zh' ? info.titleZh : info.titleEn}</span>
        </div>
        <div class="card-extra">
          <span class="details-icon details-btn-${noticeKey}"></span>
          <span class="close close-${noticeKey}"></span>
        </div>
      </div>
      <div class="card-body">
        <div class="message message-${noticeKey}">
          <div>${lang === 'zh' ? info.contentZh : info.contentEn}</div>
        </div>
        <div class="details-message details-${noticeKey}">
          <div class="item">
            <div class="name">Instance ID</div>
            <div class="value">${info.instanceId}</div>
          </div>
          <div class="item">
            <div class="name">Peer Name</div>
            <div class="value">${info.peerName}</div>
          </div>
          <div class="item">
            <div class="name">Time</div>
            <div class="value">${dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')}</div>
          </div>
          <div class="item">
            <div class="name">Module</div>
            <div class="value">${info.moduleName}</div>
          </div>
          <div class="item">
            <div class="name">Operate in</div>
            <div class="value">${info.operateIn}</div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <span class="time">${dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')}</span>
        <div class="btn-wrap" style="display: ${config.button && config.button.zh && config.button.en && config.button.params ? 'block' : 'none'}">
          <button class="btn btn-${noticeKey}">${lang === 'zh' ? config.button?.zh : config.button?.en}</button>
        </div>
      </div>
    </div>
  `;
  const notificationDiv = document.createElement('div');
  notificationDiv.classList.add('notification-box');
  notificationDiv.classList.add(`notification-box-${noticeKey}`);
  notificationDiv.innerHTML = htmlStr;

  const target = config.bodyTarget;
  target.append(notificationDiv);

  const closeElement = target.querySelector(`.close-${noticeKey}`);
  const notificationElement = target.querySelector(`.notification-box-${noticeKey}`);
  const detailsElement = target.querySelector(`.details-btn-${noticeKey}`);
  const btnElement = target.querySelector(`.btn-${noticeKey}`);

  closeElement.addEventListener('click', function () {
    target.removeChild(notificationElement);
  });

  detailsElement.addEventListener('click', function() {
    if(!detailsElement.classList.toString().includes('active'))
    {
      detailsElement.classList.add('active');
      target.querySelector(`.message-${noticeKey}`).style.display = 'none';
      target.querySelector(`.details-${noticeKey}`).style.display = 'flex';
    }
    else
    {
      detailsElement.classList.remove('active');
      target.querySelector(`.details-${noticeKey}`).style.display = 'none';
      target.querySelector(`.message-${noticeKey}`).style.display = 'block';
    }
  });

  btnElement.addEventListener('click', function() {
    broadcast?.emit('application_message', {
      'type': 'openApplication',
      'data': {
        'appModuleName': config.moduleName,
        'from': 'app-essential-notice',
        ... config.button.params
      }
    });
  });
};

export { createNotificationMessage };
