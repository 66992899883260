/*
 * @Author: laibin
 * @email: laisan8848@163.com
 * @Date: 2025-01-06 11:05:40
 * @LastEditors: laibin
 * @LastEditTime: 2025-02-28 17:10:10
 * @Description: file content
 */
import { searchModule } from '../api';
import { set } from 'lodash';
import { createUuid } from '@/utils';
import { initDesktopPool } from '../desktopPool';
const getStorageMap = new Map();

export const getStorage = () =>
{
  if (top.Storage)
  {
    return Promise.resolve(top.Storage);
  }
  return new Promise((resolve) =>
  {
    const key = createUuid();
    getStorageMap.set(key, resolve);
  });
};

export const initStorage = async () =>
{
  let { moduleVersion } = await searchModule({'moduleName': 'web-onecloud-storage'});
  let url = `/web/storage.sys/${moduleVersion}/storage.js`;
  const fn = new Function(`return import("${url}")`);
  const module = await fn();
  set(window, 'top.Storage', module);
  if(!window.kioskLogin)
  {
    set(window, 'top.StorageCacheInstance', new module.CacheManager());
    initDesktopPool();
  }
  if (getStorageMap.size)
  {
    Array.from(getStorageMap).forEach((v) =>
    {
      v[1]?.(top.Storage);
      getStorageMap.delete(v[0]);
    })
  }
}