/*
 * @Description:
 * @Author: laojunpeng
 * @Date: 2022-09-29 09:33:25
 * @LastEditTime: 2024-03-16 20:03:30
 * @LastEditors: laibin
 * @FilePath: /src/CodiggerWeb/http/HttpClient.js
 *
 * This software is the confidential and proprietary information of
 * OneCloud Co., Ltd. ('Confidential Information'). You shall not
 * disclose such Confidential Information and shall use it only in
 * accordance with the terms of the license agreement you entered
 * into with OneCloud.
 */


import { HTTP_SUCCESS_CODE, HTTP_ERROR_CODE, HTTP_RESPONSE_SUCCESS_CODES } from './code';
import axios from 'axios';

import { isNumber, isNil, isArray, isFunction } from 'lodash';

import { logout } from '../../user';
export default class Http
{
  constructor (opt)
  {
    // timeout 类型判断
    if(!isNil(opt.timeout) && !isNumber(opt.timeout))
    {
      throw Error('createAxios options timeout must be number');
    }
    // successCodes 类型判断
    if(!isNil(opt?.requestOptions?.successCodes) && !isArray(opt?.requestOptions?.successCodes))
    {
      throw Error('createAxios options successCodes must be array');
    }
    const options = Object.assign({}, {
      'timeout': 20 * 1000,
      // 配置项，下面的选项都可以在独立的接口请求中覆盖
      'requestOptions': {
        // 是否返回原生响应头 比如：需要获取响应头时使用该属性
        'isReturnNativeResponse': false,
        // 消息提示类型
        // 'errorMessageMode': 'message',
        // 是否修改成功状态码
        'successCodes': HTTP_RESPONSE_SUCCESS_CODES,
        // 错误码及后续处理
        'errorCodeConfig':{
          [HTTP_ERROR_CODE.LOGIN_COOKIE_EXPIRE] : () =>
          {
            this.toLogin();
          },
          [HTTP_ERROR_CODE.LOGIN_COOKIE_EXPIRE2] : () =>
          {
            this.toLogin();
          },
          [HTTP_ERROR_CODE.LOGIN_COOKIE_EXPIRE3] : () =>
          {
            this.toLogin();
          },
          timeoutToast: false
        },
        'timeoutToast':false
      }
    }, opt);

    // 配置项数据
    this.options = options;
    // axios实例
    this.axiosInstance = axios.create(options);

    this.options?.callback?.(this.axiosInstance);

    this.setupInterceptors();
  }

  toLogin ()
  {
    setTimeout(() =>
    {
      window.location.href = '/';
    }, 3000);
  }

  /**
   * @description: refreshToken
   */
  async toRefreshToken ()
  {
    // jwt token失效
    // let refresh_token = localStorage.getItem('oauth-refreshToken');
    // let resMsg = await refreshToken({'refresh_token':refresh_token});
    // if(resMsg.code === 0)
    // {
    //   let info = JSON.parse(resMsg.data);
    //   localStorage.setItem('oauth-token', info.access_token);
    //   localStorage.setItem('oauth-refreshToken', info.refresh_token);
    // }
    // else
    // {
      // 退出登录
    // }
  }

  /**
   * @description: Set general header
   */
  setHeader (headers)
  {
    if (!this.axiosInstance)
    {

      return false;
    }

    return Object.assign(this.axiosInstance.defaults.headers, headers);
  }

  setupInterceptors ()
  {
    const { transform = {} } = this.options;

    const {
      requestInterceptors,
      requestInterceptorsCatch,
      responseInterceptors,
      responseInterceptorsCatch
    } = transform;


    this.axiosInstance.interceptors.request.use((config) =>
    {
      let token = config.token ? config.token : localStorage.getItem('oauth-token');
      // dev_1.5.4_O0526 设置gateway接口token
      if (token && config.oauth && !config.neat)
      {
        config.headers.Authorization = `Bearer ${token}`;
      }
      // if(token && /\/web-api\/gateway\//g.test(config.url) && !config.headers.authorization)
      if(token && !config.headers.authorization && !config.neat)
      {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (requestInterceptors)
      {
        return requestInterceptors(config, this.options);
      }
      const {requestOptions, ...opts} = this.options;

      // opts 为创建实例时的默认值
      // config 为请求时传入的配置 config优先级更高
      return Object.assign({}, opts, config);
    },
    (error) =>
    {
      if(requestInterceptorsCatch)
      {
        return requestInterceptorsCatch(error);
      }
      // console.log('requestInterceptorsCatch', error);
    });

    // 请求后的结果处理
    this.axiosInstance.interceptors.response.use((response) =>
    {
      return response;
    },
    (error) =>
    {
      console.log('responseInterceptorsCatch', error);
      // token invalid
      if([401, 403].includes(error?.response?.status))
      {
        if(error?.response?.status === 401 && window.top.electronAPI)
        {
          window.top.electronAPI?.send?.('web-log-out');
        }
        logout();
      }
      if(responseInterceptorsCatch)
      {
        return responseInterceptorsCatch(error);
      }
      return error;
    });
  }

  /**
   * @description: RESTFul接口转换,从地址拼接转换为参数
   * @param {*} url
   * @param {*} opts
   * @return {*}
   * @author: laojunpeng
   */
  convertRESTAPI (url, opts)
  {
    // 判断没有正确参数则返回
    if(!opts || !opts.path)
    {
      return url;
    }

    // 获取要拼接的key值
    const pathKeys = Object.keys(opts.path);

    // 循环拼接
    pathKeys.forEach((key) =>
    {
      // 正则匹配位置
      const r = new RegExp('(:' + key + '|{' + key + '})', 'g');

      // 替换字符
      url = url.replace(r, opts.path[key]);
    });

    return url;
  }

  getArgs (args)
  {
    let config = {},
      options = {};
    if (typeof args[0] == 'object')
    {
      config = args[0];
      options = args[1] || {};
      return { config, options };
    }
    else if (typeof args[0] === 'string')
    {
      config = { url: args[0], params: args[1] || undefined };
      options = args[2] || {};
      return { config, options };
    }
    else
    {
      throw new Error('Invalid first argument for HTTP');
    }
  }

  get (...args)
  {
    const { config, options } = this.getArgs(args);
    return this.request({ ...config, method: 'GET' }, options);
  }

  post (...args)
  {
    const { config, options } = this.getArgs(args);
    return this.request({ ...config, method: 'POST' }, options);
  }

  put (...args)
  {
    const { config, options } = this.getArgs(args);
    return this.request({ ...config, method: 'PUT' }, options);
  }

  delete (...args)
  {
    const { config, options } = this.getArgs(args);
    return this.request({ ...config, method: 'DELETE' }, options);
  }

  async request (
    config,
    opt
  )
  {
    const options = Object.assign({}, this.options, opt);
    const { transform = {} } = options;

    const {
      requestInterceptors,
      requestInterceptorsCatch,
      responseInterceptors,
      responseInterceptorsCatch
    } = transform;
    
    const { isReturnNativeResponse, successCodes, errorCodeConfig, timeoutToast } = options.requestOptions;
    const result = await this.axiosInstance.request(config);

    if (responseInterceptors)
    {
      return responseInterceptors(result);
    }
    // 是否返回原生响应头 比如：需要获取响应头时使用该属性
    if(isReturnNativeResponse)
    {
      return result;
    }
    const { status, data: resData } = result;
    // 非200 http状态码
    if(status !== HTTP_SUCCESS_CODE)
    {
      if(result.stack.indexOf('timeout') > -1)
      {
        return Promise.reject({'message': '请求超时', 'error': result});
      }
      return Promise.reject({'message':`网络状态出错${status ? ',' + status : ''}`, 'error':result});
    }
    // 接口返回的code
    // 成功code处理
    if(successCodes.includes(Number(resData.code)))
    {
      return dealResData(options.code, resData);
    }

    // 错误code处理
    if(errorCodeConfig && Object.keys(errorCodeConfig).includes(Number(resData.code)))
    {
      const handler = errorCodeConfig[resData.code];

      if(isFunction(handler))
      {
        handler();
      }
    }
    return Promise.reject(resData);
  }
}

function dealResData (isSingleLayerData, resData)
{
  if(isSingleLayerData)
  {
    return resData;
  }
  if(resData.data || resData.data === null )
  {
    return resData.data
  }
  return resData;
};
