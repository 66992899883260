const privateMethods = new Map();

class AppInfo
{
  constructor ()
  {
    this.appInfoList = [];

    /**
     * update app info
     * @param {object} appInfo 
     * @param {number} idx 
     */
    const update = (appInfo, idx) =>
    {
      const currentAppInfo = this.appInfoList[idx];
      this.appInfoList.splice(idx, 1, {
        ...currentAppInfo,
        ...appInfo
      });
    };

    /**
     * create app info
     * @param {object} appInfo 
     */
    const create = (appInfo) =>
    {
      this.appInfoList.push(appInfo);
    }

    privateMethods.set('update', update);
    privateMethods.set('create', create);
  }

  /**
   * get app info
   * priority anchorId, second modulename, unite workstationId and parentEnv
   */
  get (appInfo = {}, useIndex = false)
  {
    if (typeof appInfo === 'string')
    {
      appInfo = { moduleName: appInfo };
    }

    const callback = (app) => (
      appInfo.anchorId === app.anchorId || (
        app.moduleName === appInfo.moduleName && (
          !appInfo.workstationId ||
          appInfo.workstationId === app.workstationId
        ) && (
          !appInfo.parentEnv || (
            appInfo.parentEnv === app.parentEnv
          )
        )
      )
    );

    if (useIndex)
    {
      return this.appInfoList.findIndex(callback);
    }
    return this.appInfoList.filter(callback);
  }

  /**
   * set app info
   * @param {object} appInfo 
   */
  set (appInfo)
  {
    const idx = this.get(appInfo, true);
    if (idx > -1)
    {
      privateMethods.get('update')(appInfo, idx);
    }
    else
    {
      privateMethods.get('create')(appInfo);
    }
  }

  /**
   * destory app info
   * @param {object} appInfo 
   */
  remove (appInfo)
  {
    const idx = this.get(appInfo, true);
    if (idx > -1)
    {
      this.appInfoList.splice(idx, 1);
    }
  }
}

export default new AppInfo();
