import httpClient from '@/api/http';
import { base64ToFile, urlToFile } from '@/utils';
import { baseURL } from '../api/http';

 /**
  * base64转File
  * @param {string} base64 图片base64 
  * @param {string} mime 图片类型
  * @param {string} filename 图片名称
  * @returns ie
  */
export async function upload (base64, mime, filename)
{
  if (!base64)
  {
    return Promise.reject();
  }
  return new Promise((resolve, reject) =>
  {
    const file = base64ToFile(base64, mime, filename);
    // 上传图片到服务器
    const uploadParams = new FormData();
    uploadParams.append('file', file);
    httpClient.post({
      url: `${baseURL.common}/common/upload/file`,
      oauth: true,
      data: uploadParams,
      successCodes: 200
    }).then((url) =>
    {
      if (url)
      {
        resolve(`${baseURL.common}${url}`);
      }
      else
      {
        reject();
      }
    }).catch(reject);
  });
};