/*
 * @Description:
 * @Author: laojunpeng
 * @Date: 2022-09-28 16:53:31
 * @LastEditTime: 2022-09-29 10:59:37
 * @LastEditors: laojunpeng
 * @FilePath: /src/CodiggerWeb/http/index.js
 *
 * This software is the confidential and proprietary information of
 * OneCloud Co., Ltd. ('Confidential Information'). You shall not
 * disclose such Confidential Information and shall use it only in
 * accordance with the terms of the license agreement you entered
 * into with OneCloud.
 */
import HttpClient from './HttpClient';
import queryString from 'query-string';

const list = process.env;
const getEnv = (env) => {
  return list[`VUE_APP_${env}`];
};

let baseURL = {
  portal: `${location.origin}/web-api/portal`,
  boss: `${location.origin}/web-api/boss`,
  dotfiles: `${location.origin}/web-api/dotfiles`,
  httpbridge: `${location.origin}/web-api/httpbridge`,
  remote: `${location.origin}`,
  common: `${location.origin}/web-api/file`,
  systemKeeping: `${location.origin}/web-api/systemKeeping`,
  dataCenter: `${location.origin}/web-api/datacenter`,
  wallet: getEnv('WALLET_URL'),
  hottub: getEnv('HOT_TUB_URL'),
  yespmpChat: getEnv('YESPMP_CHATSERVER'),
  onecloudChat: getEnv('ONECLOUD_CHATSERVER'),
  meeting: getEnv('MEETING_URL'),
  shortLink: getEnv('SL_URL'),
  login: getEnv('LOGIN'),
};

let wsURL = {
  remote: `wss://${location.origin}`,
  edge: `wss://${location.origin}/web-api/edge`,
};

const baseServer = new HttpClient({
  baseURL: '',
});

const commonServer = new HttpClient({
  baseURL: baseURL.common,
});

const portalServer = new HttpClient({
  baseURL: baseURL.portal,
});

const bossServer = new HttpClient({
  baseURL: baseURL.boss,
});

const hottubServer = new HttpClient({
  baseURL: baseURL.hottub,
});

const yespmpChatServer = new HttpClient({
  baseURL: baseURL.yespmpChat,
});

const onecloudChatServer = new HttpClient({
  baseURL: baseURL.onecloudChat,
});

const meetingServer = new HttpClient({
  baseURL: baseURL.meeting,
});

const shortLinkServer = new HttpClient({
  baseURL: baseURL.shortLink,
});

let edgeServer, systemKeepingServer, dataCenterServer, userServer, walletServer;

export const updateBaseUrl = () => {
  const queryParams = queryString.parse(location.search);
  const oauthToken = queryParams?.jwtToken || localStorage.getItem('oauth-token');
  if (!oauthToken) return;
  const tokenInfo = JSON.parse(atob(oauthToken.split('.')[1]));
  let { region, pod } = tokenInfo;
  const url = process.env.VUE_APP_PUBLISH_ENV === 'big' ? 'codigger.com' : 'onecloud.cn';
  baseURL = {
    user: `https://genesis.${url}/web-api/user`,
    wallet: `https://genesis.${url}/web-api/wallet`,
    portal: `https://${region}.${url}/web-api/portal`,
    boss: `https://${region}.${url}/web-api/boss`,
    dotfiles: `https://${region}.${url}/web-api/dotfiles`,
    httpbridge: `https://${region}.${url}/web-api/httpbridge`,
    remote: `https://${region}.${url}`,
    edge: `https://${region}-${pod}.${url}/web-api/edge`,
    systemKeeping: `https://${region}-${pod}.${url}/web-api/system-keeping`,
    dataCenter: `https://${region}-${pod}.${url}/web-api/datacenter`,
    common: `https://${region}.${url}/web-api/file`,
    hottub: getEnv('HOT_TUB_URL'),
    yespmpChat: getEnv('YESPMP_CHATSERVER'),
    onecloudChat: getEnv('ONECLOUD_CHATSERVER'),
    meeting: getEnv('MEETING_URL'),
    shortLink: getEnv('SL_URL'),
    login: getEnv('LOGIN'),
  };
  wsURL = {
    remote: `wss://${region}.${url}`,
    edge: `wss://${region}-${pod}.${url}/web-api/edge`,
    wallet: `wss://genesis.${url}/web-api/wallet`,
  };
  edgeServer = new HttpClient({
    baseURL: baseURL.edge,
  });
  systemKeepingServer = new HttpClient({
    baseURL: baseURL.systemKeeping,
  });
  dataCenterServer = new HttpClient({
    baseURL: baseURL.dataCenter,
  });
  userServer = new HttpClient({
    baseURL: baseURL.user,
  });
  walletServer = new HttpClient({
    baseURL: baseURL.wallet,
  });
};

updateBaseUrl();

export {
  baseURL,
  wsURL,
  baseServer,
  commonServer,
  portalServer,
  bossServer,
  edgeServer,
  systemKeepingServer,
  dataCenterServer,
  userServer,
  walletServer,
  hottubServer,
  yespmpChatServer,
  onecloudChatServer,
  meetingServer,
  shortLinkServer,
};

export default new HttpClient({
  // 可配置项，详见CodiggerWeb/api/utils的createAxios
});
