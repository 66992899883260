import axios from "axios";

/**
 * 生成 uuid
 * @param {*} len
 * @param {*} radix
 * @returns
 */
 export function createUuid (len = 8, radix = 10)
 {
   let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
 
   let uuid = [],
     i;
 
   let rdx = radix || chars.length;
 
   if (len)
   {
     // Compact form
     for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * rdx];
   }
   else
   {
     // rfc4122, version 4 form
     let r;
     // rfc4122 requires these characters
 
     uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
 
     uuid[14] = '4';
 
     // Fill in random data.  At i==19 set the high bits of clock sequence as
     // per rfc4122, sec. 4.1.5
 
     for (i = 0; i < 36; i++)
     {
       if (!uuid[i])
       {
         r = 0 | Math.random() * 16;
         uuid[i] = chars[i === 19 ? r & 0x3 | 0x8 : r];
       }
     }
   }
 
   return uuid.join('');
 }

 /**
  * base64转File
  * @param {string} base64 图片base64 
  * @param {string} mime 图片类型
  * @param {string} filename 图片名称
  * @returns 
  */
export function base64ToFile (base64, mime, filename)
{
  const arr = base64.split(',');
  let type = 'image/png';
  if (arr.length > 1)
  {
    type = mime || arr[0].match(/:(.*?);/)[1];
  }
  const suffix = type.split('/')[1];
  filename = filename || `image.${suffix}`;
  const bstr = atob(arr[1] || arr[0]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--)
  {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type,
    lastModified: +new Date()
  });
}

 /**
  * blob转File
  * @param {string} blob Blob 
  * @param {string} mime 图片类型
  * @param {string} filename 图片名称
  * @returns 
  */
export function blobToFile (blob, filename = 'image.png')
{
  const file = new File([blob], filename, {  
    type: 'text/plain',  
    lastModified: +new Date()
  });  
  return file;  
};
