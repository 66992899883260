import { user } from "../user";
import loadingBg from "@/assets/images/collection-loading-bg.webp"
import tipBoxBg1 from "@/assets/images/tip-box-bg1.png"
import tipBoxBg2 from "@/assets/images/tip-box-bg2.png"
import tipBoxBg3 from "@/assets/images/tip-box-bg3.png"
import tipBtnBg from "@/assets/images/tip-btn-bg.png"
import confirmLoading from "@/assets/images/confirm-loading.png"


let progressIntervalId;
class CodiggerLoading {
  loadingKey = 'codigger-collection-loading';
  loadingEle;
  // loadingTextEle;
  // loadingProgressEl;
  intervalId;
  
  isLoading = false;
  visible = false;
  screenshots = '';
  progress = 0;
  visibleKey = 'codigger-collection-loading-visible';
  loadingBgKey = 'codigger-collection-loading-background';
  language = 'en';

  constructor(){
    const loadingEle = document.createElement('div');
    const loadingStyle = {
      position: 'fixed',
      left: '0',
      right: '0',
      top: '0',
      bottom: '0',
      // backgroundImage: `url(${loadingBg})`,
      // backgroundSize: `${document.body.style.width} ${document.body.style.height}`,
      // backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: '9'
    };
    Object.assign(loadingEle.style, loadingStyle);

    const mask = document.createElement('div');
    const maskStyle = {
      position: 'absolute',
      left: '0',
      right: '0',
      top: '0',
      bottom: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      // backdropFilter: 'blur(10px)',
      zIndex: '-1'
    }
    this.mask = mask;
    Object.assign(mask.style, maskStyle);
    loadingEle.appendChild(mask);

    const loadingBoxEle = document.createElement('div');
    loadingEle.appendChild(loadingBoxEle);
    
    // 弹窗
    const tipBox = document.createElement('div');
    loadingBoxEle.appendChild(tipBox);
    const tipBoxStyle = {
      width: '540px',
      height: '294px',
      position: 'absolute',
      top: '366px',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundImage: `url(${tipBoxBg1})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }
    Object.assign(tipBox.style, tipBoxStyle);
    this.tipBox = tipBox;

    const tipTitle = document.createElement('div');
    tipBox.appendChild(tipTitle);
    const tipTitleStyle = {
      position: 'absolute',
      top: '108px',
      left: '80px',
      fontSize: '18px',
      color: '#1C2635',
      fontWeight: 'bold'
    }
    Object.assign(tipTitle.style, tipTitleStyle);
    tipTitle.textContent = 'Tips';

    const tipText = document.createElement('div');
    tipBox.appendChild(tipText);
    const tipTextStyle = {
      position: 'absolute',
      top: '156px',
      left: '55px',
      right: '60px',
      fontSize: '16px',
      color: '#FFF',
      lineHeight: '1.5'
    }
    Object.assign(tipText.style, tipTextStyle);
    tipText.innerHTML = "确认要将当前窗口的Collection更换为<span style='color:#F4FF25'>{newCollection}</span>吗？";
    this.tipText = tipText;

    const tipBtn = document.createElement('div');
    tipBox.appendChild(tipBtn);
    const tipBtnStyle = {
      position: 'absolute',
      top: '280px',
      left: '140px',
      backgroundImage: `url(${tipBtnBg})`,
      backgroundSize: 'contain',
      width: '260px',
      height: '38px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      color: '#fff',
      fontSize: '16px',
      fontWeight: 'bold',
      cursor: 'pointer',
      lineHeight: '38px',
      textAlign: 'center'
    }
    Object.assign(tipBtn.style, tipBtnStyle);

    const confirmBtn = document.createElement('div');
    tipBtn.appendChild(confirmBtn);
    const confirmBtnStyle = {
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
    Object.assign(confirmBtn.style, confirmBtnStyle);

    // 1. 创建 <style> 节点并插入 CSS
    const style = document.createElement('style');
    style.textContent = `
      @keyframes btnRotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      .rotating-img {
        animation: btnRotate 1s linear infinite;
        display: none;
      }
    `;
    loadingEle.appendChild(style);
    // 2. 创建 <img> 节点
    const confirmLoadingEl = document.createElement('img');
    confirmLoadingEl.src = confirmLoading; // 替换为你的图片路径
    confirmLoadingEl.classList.add('rotating-img'); // 添加旋转动画的类
    confirmLoadingEl.style.marginRight = '5px';
    // 3. 将 <img> 节点添加到 DOM 中
    confirmBtn.appendChild(confirmLoadingEl);
    this.confirmLoadingEl = confirmLoadingEl;
    
    const confirmBtnText = document.createElement('span');
    confirmBtnText.innerHTML = this.language === 'zh' ? '确定' : 'Yes';

    this.confirmBtn = confirmBtn;
    this.confirmBtnText = confirmBtnText;
    confirmBtn.appendChild(confirmBtnText);

    const cancelBtn = document.createElement('div');
    tipBtn.appendChild(cancelBtn);
    const cancelBtnStyle = {
      width: '50%'
    }
    Object.assign(cancelBtn.style, cancelBtnStyle);
    const cancelBtnText = document.createElement('span');
    cancelBtnText.innerHTML = this.language === 'zh' ? '取消' : 'No';
    cancelBtn.appendChild(cancelBtnText);
    this.cancelBtn = cancelBtn;
    this.cancelBtnText = cancelBtnText;

    this.loadingEle = loadingEle;
  
  }

  async init () {
    console.log(this.loadingKey);
    this.language = window.top.codigger.i18n.getLanguage() || 'en'
    // console.log('getLanguage', window.top.codigger.i18n.getLanguage());
    let collectionData, showLoading;
    console.log('window.top.electronAPI', window.top.electronAPI);
    if(window.top.electronAPI) {
      const data = await window.top.electronAPI?.invoke('web-get-desktopGlobalData');
      console.log('data--------', data);
      collectionData = JSON.parse(data[this.loadingKey] || null);
      showLoading = data[this.visibleKey] == '1';
      // show = data[this.loadingKey];
      // this.progress = data[this.progressKey];
    } else {
      collectionData = JSON.parse(localStorage.getItem(this.loadingKey) || null);
      showLoading = localStorage.getItem(this.visibleKey) == '1';
      // this.progress = localStorage.getItem(this.progressKey);
    }
    if(!!collectionData && showLoading) {
      this.confirmLoadingEl.style.display = 'block';
      this.isLoading = true;
      this.loadingEle.style.backgroundImage = `url(${loadingBg})`
      this.mask.style.backgroundColor= 'rgba(0, 0, 0, 0.9)';
      this.show(collectionData);
    };
  }

  setBackground(value) {
    if(!this.visible) return;
    if(window.top.electronAPI) {
      window.top.electronAPI.send('web-set-desktopGlobalData', {
        [this.loadingBgKey]: value
      });
    } else {
      localStorage.setItem(this.loadingBgKey, value);
    }
    this.screenshots = value;
    this.loadingEle.style.backgroundImage = `url(${value})`
    this.loadingEle.style.backgroundSize = `${document.body.style.width} ${document.body.style.height}`
    if(this.isLoading){
      this.options?.onConfirm?.();
    }
  }

  onConfirm() {
    if(this.isLoading) return;
    this.confirmLoadingEl.style.display = 'block';
    this.isLoading = true;
    if(window.top.electronAPI) {
      window.top.electronAPI.send('web-set-desktopGlobalData', {
        [this.visibleKey]: '1'
      });
    } else {
      localStorage.setItem(this.visibleKey, '1');
    }
    if(this.screenshots || this.isSafari()){
      this.options?.onConfirm?.();
    }
  }

  onCancel() {
    if(this.isLoading) return;
    this.hide();
    this.options?.onCancel?.();
  }

  async show (collectionData, options) {
    this.language = window.top.codigger.i18n.getLanguage() || 'en';
    console.time('showCollectionTime1------');
    console.time('showCollectionTime2------');
    let imgUrl;
    if(window.top.electronAPI) {
      window.top.electronAPI.send('web-set-desktopGlobalData', {
        [this.loadingKey]: JSON.stringify(collectionData) || ''
      });
      const data = await window.top.electronAPI?.invoke('web-get-desktopGlobalData');
      imgUrl = data[this.loadingBgKey];
    } else {
      localStorage.setItem(this.loadingKey, JSON.stringify(collectionData));
      imgUrl = localStorage.getItem(this.loadingBgKey);
    }    
    imgUrl && (this.loadingEle.style.backgroundImage = `url(${imgUrl})`);
    if (collectionData.online == '0') {
      this.tipBox.style.backgroundImage = `url(${tipBoxBg1})`
      this.tipText.innerHTML =  this.language === 'zh' ? `确认要将当前窗口的Collection更换为<span style='color:#F4FF25'>${collectionData.name}</span>吗？` : `Are you sure you want to change to <span style='color:#F4FF25'>${collectionData.name}</span>？`
    }else if(collectionData.screenCount == '1'){
      this.tipBox.style.backgroundImage = `url(${tipBoxBg3})`
      this.tipText.innerHTML =  this.language === 'zh' ? `你选择的Collection正在其他地方使用，请确认是否强制切换到<span style='color:#F4FF25'>${collectionData.name}</span>？` : `<span style='color:#F4FF25'>${collectionData.name}</span> is in use in other split screens. Do you want to swap Collections?`
    }else{
      this.tipBox.style.backgroundImage = `url(${tipBoxBg2})`
      this.tipText.innerHTML =  this.language === 'zh' ? `你选择的<span style='color:#F4FF25'>${collectionData.name}</span>已显示在其他分屏中，请确认是否进行Collection对调？` : `This Collection is currently in use. Force switch to <span style='color:#F4FF25'>${collectionData.name}</span>?`
    }
    this.confirmBtnText.innerHTML = this.language === 'zh' ? '确定' : 'Yes';
    this.cancelBtnText.innerHTML = this.language === 'zh' ? '取消' : 'No';
    if(options){
      this.options = options;
      this.confirmBtn.removeEventListener('click', this.onConfirm);
      this.cancelBtn.removeEventListener('click', this.onCancel);
      this.confirmBtn.addEventListener('click', this.onConfirm.bind(this));
      this.cancelBtn.addEventListener('click', this.onCancel.bind(this));
    }
    document.body.append(this.loadingEle);
    this.visible = true;

  }
  hide () {

    if(window.top.electronAPI) {
      window.top.electronAPI.send('web-set-desktopGlobalData', {
        [this.loadingKey]: '',
        [this.loadingBgKey]: '',
        [this.visibleKey]: ''
      });
    } else {
      localStorage.removeItem(this.loadingKey);
      localStorage.removeItem(this.loadingBgKey);
      localStorage.removeItem(this.visibleKey);
    } 
    this.mask.style.backgroundColor= 'rgba(0, 0, 0, 0.7)';
    this.confirmLoadingEl.style.display = 'none';
    this.isLoading = false;
    this.screenshots = '';
    this.loadingEle.style.backgroundImage = '';
    this.loadingEle?.remove();
    this.visible = false;
  }

  isSafari () {
    const userAgent = navigator.userAgent.toLocaleLowerCase();
    return userAgent.includes('safari') && !userAgent.includes('chrome');
  }
}

export default new CodiggerLoading();