import httpClient, { systemKeepingServer } from './http';

/**
* 查询app anchor list
* @param data
* @returns
*/
export const queryAppAnchorList = (data) => systemKeepingServer.get({
  'url': `/app/anchor/getAppAnchorByIds`,
  'params': data,
  'oauth': true
});

/**
* 查询fileExtensionHandler信息
* @param data
* @returns
*/
export const queryFileExtensionHandler = (data) => systemKeepingServer.get({
  'url': `/file/anchor/extension/query`,
  'params': data,
  'oauth': true
});

/**
* 新增fileExtension信息
* @param data
* @returns
*/
export const createRoseFileExtensionHandler = (data) => systemKeepingServer.post({
  'url': `/file/anchor/extension/roseCreateFileExtension`,
  'oauth': true,
  data
});

/**
* 新增desktop file信息
* @param data
* @returns
*/
export const addDesktopFile = (data) => systemKeepingServer.post({
  'url': `/desktop/file/add`,
  'oauth': true,
  data
});

/**
* 查询desktop file信息
* @param data
* @returns
*/
export const queryDesktopFile = (data) => systemKeepingServer.get({
  'url': `/desktop/file/get`,
  'oauth': true,
  'params': data
});

/**
* 查询desktop file列表信息
* @param data
* @returns
*/
export const queryDesktopFileList = (data) => systemKeepingServer.get({
  'url': `/desktop/file/list`,
  'oauth': true,
  'params': data
});

/**
* 查询file anchor信息
* @param data
* @returns
*/
export const addFileAnchor = (data) => systemKeepingServer.post({
  'url': `/file/anchor/add`,
  'oauth': true,
  data
});

/**
* 查询file anchor list信息
* @param data
* @returns
*/
export const queryFileAnchorList = (data) => systemKeepingServer.get({
  'url': `/file/anchor/list`,
  'oauth': true,
  'params': data
});

/**
* 查询file anchor信息
* @param data
* @returns
*/
export const queryFileAnchor = (data) => systemKeepingServer.get({
  'url': `/file/anchor/get`,
  'oauth': true,
  'params': data
});
/**
* 设置文件默认方式
* @param data
* @returns
*/
export const updateFileExtensionSeq = (data) => systemKeepingServer.post({
  'url': `/file/anchor/extension/updateSeq`,
  'oauth': true,
   data
});
