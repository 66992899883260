export function isElement (node)
{
  return Object.prototype.toString.call(node).includes('[object HTML');
}

export function isWindow (instance)
{
  return instance instanceof Window;
}

export function getElement (node, doc)
{
  let element = null;
  // is element
  if (isElement(node))
  {
    element = node;
  }
  else if (typeof node === 'string')
  {
    const dom = doc?.querySelector?.(node);
    if (isElement(dom))
    {
      element = dom;
    }
  }
  return element;
};

export function getDesktopInstance (instance, targetName = 'codigger-desktop')
{
  if (instance.document.name === targetName)
  {
    return instance;
  }
  const parent = instance.parent;
  if (parent === instance)
  {
    return null;
  }
  return getDesktopInstance(parent, targetName);
}
